const base_url: string = 'https://api.dieselgate.com';
import {
  CheckExistingClaimRequest, CheckExistingClaimResponse,
  CheckRequest,
  SubmitClaimRequest,
  SubmitCompanyCheckRequest
} from "@/types";

export const submitCheck = async (checkRequest: CheckRequest) => {
  const response = await fetch(`${ base_url }/claim/check`, {
    method: 'POST',
    body: JSON.stringify(checkRequest),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return await response.json();
};

export const submitClaim = async (claimRequest: SubmitClaimRequest) => {
  const response = await fetch(`${ base_url }/submit/claim`, {
    method: 'POST',
    body: JSON.stringify(claimRequest),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return await response.json();
};


export const getOwnershipPeriods = async (licensePlate: string) => {
  const response = await fetch(`${ base_url }/vehicle/${ licensePlate }/ownership-history`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const result = await response.json();

  if (result.statusCode && result.statusCode === 400) {
    return [];
  }

  return result;
};

export const checkExistingClaim = async (request: CheckExistingClaimRequest): Promise<CheckExistingClaimResponse> => {
  const response = await fetch(`${ base_url }/claim/existing`, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return await response.json() as CheckExistingClaimResponse;
}

export const getKvkOptions = async (query: string) => {

  const response = await fetch(
    `${ base_url }/company/search/${ query }`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      method: 'GET'
    });
  const result = await response.json();
  if (result.numberOfItems > 0) {
    return result.options
  }
  return [];
}

export const checkEmailValid = async (email: string) => {
  const response = await fetch(`${ base_url }/mail/validate`, {
    method: 'POST',
    body: JSON.stringify({email: email}),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return await response.json();
}


export const submitCompanyCheckSubmission = async (request: SubmitCompanyCheckRequest) => {
  const response = await fetch(`${ base_url }/company/submit`, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return await response.json();
}