import { v4 as uuidv4 } from 'uuid';
import { makeAutoObservable } from "mobx";

export type Company = {
  id?: string;
  companyName: string;
  kvkIdentifier: string;
}

export class CompanyCheckStore {

  email: string = '';
  firstname: string = '';
  lastname: string = '';
  jobtitle: string = '';
  phone: string = '';

  companies: Company[] = [{
    id: uuidv4(),
    kvkIdentifier: '',
    companyName: ''
  }];

  tradeAssociation: string = '';
  tradeAssociationMembershipNumber: string = '';
  tradeAssociationCustom: string = '';

  constructor() {
    makeAutoObservable(this);
  }
  // Used for form
  isValidCompany(company: Company) {
    return company.companyName !== '' && company.kvkIdentifier !== '';
  }
  isValidCompanyList() {
    let isValid = false;

    this.companies.map(c => isValid = isValid || this.isValidCompany(c))
    return isValid;
  }
}