import { makeAutoObservable, runInAction, toJS } from "mobx"
import { getOwnershipPeriods, submitCheck, submitClaim } from "@/clients/dieselgate.client";
import {
  CheckExistingClaimRequest,
  Company,
  EligibilityCode,
  SubmitClaimRequest,
  VehicleOwnershipPeriod
} from "@/types";
import moment from 'moment';
import { getCookie } from "cookies-next";

export class ClaimStore {

  licensePlate: string = '';
  email: string = '';
  referral: string | null;

  vehicleEligibilityCode: EligibilityCode = EligibilityCode.NONE;
  vehicleModel: string = '';
  vehicleMake: string = '';
  vehicleFuelType: string = '';
  vehiclePower: number = 0;
  vehicleCylinderCapacity: number = 0;
  vehicleEmissionCode: string = '';
  vehicleDateFirstEntry: Date = new Date();

  vehicleOwnershipPeriod: VehicleOwnershipPeriod = null;
  vehicleOwnershipPeriods: VehicleOwnershipPeriod[] = [];

  customPeriodStartMonth: number = null;
  customPeriodStartYear: number = null;
  customPeriodEndMonth: number = null;
  customPeriodEndYear: number = null;

  isLease: boolean = null;
  leaseDuration: string = null;
  leaseStart: string = null;
  leaseCompany: string = null;
  leaseCompanyKVK: string = null;
  leaseCompanySelectedFromKVK: Company = null;

  isCompany: boolean = null;
  company: Company = null;

  firstname: string = '';
  lastname: string = '';
  phone: string = '';

  addressZip: string = '';
  addressHouseNumber: string = '';
  addressHouseNumberExtension: string = '';
  addressStreetname: string = '';
  addressCity: string = '';

  claimantType: string = '';
  isAuthorized: boolean = null;

  tradeAssociation: string = '';
  tradeAssociationCustom: string = '';
  tradeAssociationMembershipNumber: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  setAddressZip(addressZip: string) {
    this.addressZip = addressZip;
  }

  setAddressHouseNumber(addressHouseNumber: string) {
    this.addressHouseNumber = addressHouseNumber;
  }

  setAddressHouseNumberExtension(addressHouseNumberExtension: string) {
    this.addressHouseNumberExtension = addressHouseNumberExtension;
  }

  setAddressStreetname(addressStreetname: string) {
    this.addressStreetname = addressStreetname;
  }

  setAddressCity(addressCity: string) {
    this.addressCity = addressCity;
  }

  setFirstname(firstname: string) {
    this.firstname = firstname;
  }

  setLastname(lastname: string) {
    this.lastname = lastname;
  }

  setPhone(phone: string) {
    this.phone = phone;
  }

  setCompany(company: Company) {
    this.company = company;
  }

  setIsCompany(isCompany: boolean) {
    this.isCompany = isCompany;
  }

  setLeaseDuration(duration: string) {
    this.leaseDuration = duration;
  }

  setLeaseStart(leaseStart: string) {
    this.leaseStart = leaseStart;
  }

  setLeaseCompany(leaseCompany: string) {
    this.leaseCompany = leaseCompany
  }

  setLeaseCompanyKVK(leaseCompanyKVK: string) {
    this.leaseCompanyKVK = leaseCompanyKVK
  }

  setLeaseCompanySelectedFromKVK(leaseCompany: Company) {
    this.leaseCompanySelectedFromKVK = leaseCompany
  }

  setIsLease(isLease: boolean) {
    this.isLease = isLease;
  }

  setClaimantType(type: string) {
    this.claimantType = type;
  }

  setIsAuthorized(value: boolean) {
    this.isAuthorized = value;
  }

  getVehicleLabel(): string {
    if (this.vehicleMake === '') {
      return 'Onbekende auto';
    }
    return `${ this.vehicleMake } ${ this.vehicleModel } (${ moment(this.vehicleDateFirstEntry).format('YYYY') })`
  }

  getPeriodLabel(): string {
    if (this.vehicleOwnershipPeriod) {
      return `${ moment(this.vehicleOwnershipPeriod.start).format('DD MMM YYYY').toLowerCase() } - ${ this.vehicleOwnershipPeriod.end ? moment(this.vehicleOwnershipPeriod.end).format('DD MMM YYYY').toLowerCase() : 'Heden' }`;
    }
    if (this.customPeriodStartMonth && this.customPeriodStartYear) {
      return `${ moment().set({
        month: this.customPeriodStartMonth,
        year: this.customPeriodStartYear
      }).format('MMM YYYY').toLowerCase() } - ${ (this.customPeriodEndMonth === null || this.customPeriodEndYear === null) ? 'Heden' : moment().set({
        month: this.customPeriodEndMonth,
        year: this.customPeriodEndYear
      }).format('MMM YYYY').toLowerCase() }`;
    }
    return 'Onbekend';
  }

  setCustomPeriodStartMonth(value: number) {
    this.customPeriodStartMonth = value;
  }

  setCustomPeriodStartYear(value: number) {
    this.customPeriodStartYear = value;
  }

  setCustomPeriodEndMonth(value: number) {
    this.customPeriodEndMonth = value;
  }

  setCustomPeriodEndYear(value: number) {
    this.customPeriodEndYear = value;
  }

  async getVehicleOwnershipPeriods() {
    const periods = await getOwnershipPeriods(this.licensePlate);
    await runInAction(() => {
      this.vehicleOwnershipPeriods = periods;
    })
    return;
  }

  setEmail(email: string) {
    this.email = email;
  }

  setReferral(referral: string) {
    this.referral = referral;
  }

  setLicensePlate(licensePlate: string) {
    this.licensePlate = licensePlate
      .replace(/[^a-zA-Z0-9]/g, '')
      .toUpperCase();
  }

  setVehicleOwnershipPeriod(period: VehicleOwnershipPeriod) {
    this.vehicleOwnershipPeriod = period;
  }

  async submitCheck() {
    const response = await submitCheck({
      email: this.email,
      licensePlate: this.licensePlate,
      termsOfServiceAgreement: true,
      referral: this.referral ?? getCookie('referral')
    })

    await runInAction(() => {
      this.email = response.email;
      this.licensePlate = response.licensePlate;
      this.vehicleEligibilityCode = response.vehicleEligibilityCode;
      this.vehicleModel = response.vehicleModel;
      this.vehicleMake = response.vehicleMake;
      this.vehicleFuelType = response.vehicleFuelType;
      this.vehiclePower = response.vehiclePower;
      this.vehicleCylinderCapacity = response.vehicleCylinderCapacity;
      this.vehicleEmissionCode = response.vehicleEmissionCode;
      this.vehicleDateFirstEntry = new Date(response.vehicleDateFirstEntry);
    });

    return;
  }

  async submitClaim() {
    const request = await this.createSubmitClaimRequest();
    return await submitClaim(request);
  }

  createCheckExistingClaim() {
    let referenceDate;

    // If vehicle has an ownership period
    if (this.vehicleOwnershipPeriod) {
      referenceDate = moment(this.vehicleOwnershipPeriod.start).toISOString();

      // If user provided period
    } else {
      referenceDate = moment().set({
        date: 1,
        month: this.customPeriodStartMonth,
        year: this.customPeriodStartYear,
      }).add(1, 'month').startOf('month').toISOString();
    }

    const referenceDateDay = moment(referenceDate).format('DD');
    const referenceDateMonth = moment(referenceDate).format('MM');
    const referenceDateYear = moment(referenceDate).format('YYYY');

    const request: CheckExistingClaimRequest = {
      email: this.email,
      licensePlate: this.licensePlate,
      referenceDateDay: referenceDateDay,
      referenceDateMonth: referenceDateMonth,
      referenceDateYear: referenceDateYear
    }

    return request;
  }

  async createSubmitClaimRequest() {

    let referenceDate;

    // If vehicle has an ownership period
    if (this.vehicleOwnershipPeriod) {
      referenceDate = moment(this.vehicleOwnershipPeriod.start)
        .toISOString();

      // If user provided period
    } else {
      referenceDate = moment().set({
        date: 1,
        month: this.customPeriodStartMonth,
        year: this.customPeriodStartYear,
      }).add(1, 'month').startOf('month').toISOString();
    }

    const referenceDateDay = moment(referenceDate).format('DD');
    const referenceDateMonth = moment(referenceDate).format('MM');
    const referenceDateYear = moment(referenceDate).format('YYYY');

    let claimantType = this.claimantType ?? 'individual';

    if (this.isAuthorized === false) {
      claimantType = 'individual';
    }

    const submitClaimRequest: SubmitClaimRequest = {
      email: this.email,
      licensePlate: this.licensePlate,
      termsOfServiceAgreement: true,
      isCompany: this.isCompany,
      companyOption: this.isCompany ? toJS(this.company) : undefined,

      referenceDateDay: referenceDateDay,
      referenceDateMonth: referenceDateMonth,
      referenceDateYear: referenceDateYear,

      firstname: this.firstname,
      lastname: this.lastname,
      phone: this.phone,
      addressZip: this.addressZip,
      addressHouseNumber: this.addressHouseNumber,
      addressHouseNumberExtension: this.addressHouseNumberExtension,
      addressStreetname: this.addressStreetname,
      addressCity: this.addressCity,
      addressCountry: 'NL',
      isLeaseContract: this.isLease,
      leaseDuration: this.isLease ? parseInt(this.leaseDuration) : undefined,
      leaseDateStart: this.isLease ? new Date(this.leaseStart) : undefined,
      leaseCompany: this.isLease ? this.leaseCompany : undefined,
      leaseCompanyKVK: this.isLease ? this.leaseCompanyKVK : undefined,

      claimantType: claimantType,

      tradeAssociation: this.tradeAssociation,
      tradeAssociationCustom: this.tradeAssociationCustom,
      tradeAssociationMembershipNumber: this.tradeAssociationMembershipNumber,

      //facebook cookies
      fbc: getCookie('_fbc'),
      fbp: getCookie('_fbp')
    };
    return submitClaimRequest;
  }

  clearStore() {
    this.licensePlate = '';

    this.vehicleEligibilityCode = EligibilityCode.NONE;
    this.vehicleModel = '';
    this.vehicleMake = '';
    this.vehicleFuelType = '';
    this.vehiclePower = 0;
    this.vehicleCylinderCapacity = 0;
    this.vehicleEmissionCode = '';
    this.vehicleDateFirstEntry = new Date();

    this.vehicleOwnershipPeriod = null;
    this.vehicleOwnershipPeriods = [];

    this.customPeriodStartMonth = null;
    this.customPeriodStartYear = null;
    this.customPeriodEndMonth = null;
    this.customPeriodEndYear = null;

    this.isLease = null;
    this.leaseDuration = null;
    this.leaseStart = null;
    this.leaseCompany = null;
    this.leaseCompanyKVK = null;

    this.isCompany = null;
    this.company = null;
    this.claimantType = null;

    this.firstname = '';
    this.lastname = '';
    this.phone = '';

    this.addressZip = '';
    this.addressHouseNumber = '';
    this.addressHouseNumberExtension = '';
    this.addressStreetname = '';
    this.addressCity = '';

    this.tradeAssociation = '';
    this.tradeAssociationCustom = '';
    this.tradeAssociationMembershipNumber = '';
  }
}
