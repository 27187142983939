import { Brand } from "@/types";
import { useEffect, useState } from "react";


export const brandSupportsIcon = (branding: Brand) => {

  if (branding === 'mercedesrecall') {
    return false;
  }

  return true;
}

export const getTextIconForBrand = (branding: Brand) => {
  if (branding === 'mercedesrecall') {
    return 'MercedesRecall.nl';
  }

  return 'Dieselgate.com'
}

export const getIconURLForBrand = (branding: Brand) => {

  if (branding === 'emissionsjustice') {
    return '/assets/images/dejf-icon.png';
  }

  if (branding === 'mercedesrecall') {
    return '';
  }

  return '/assets/images/dieselgate-icon.png';
}

export const getPrimaryColorForBrand = (branding: Brand) => {
  if (branding === 'emissionsjustice') {
    return '#89C7C4';
  }

  if (branding === 'mercedesrecall') {
    return '#2557d4';
  }

  return '#E84E1A';
}


export const useBranding = () => {
  const [ branding, setBranding ] = useState(Brand.Dieselgate);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const hostname = window.location.hostname;

    if (hostname === 'emissionsjustice.dieselgate.com') {
      setBranding(Brand.EmissionsJustice);
    }

    if(hostname === 'check.mercedesrecall.nl') {
      setBranding(Brand.MercedesRecall);
    }

  }, [])

  return branding;
}