import { CookieValueTypes } from "cookies-next";

export enum Brand {
  Dieselgate = 'dieselgate',
  MercedesRecall = 'mercedesrecall',
  EmissionsJustice = 'emissionsjustice'
}

export enum EligibilityCode {
  NOT_ELIGIBLE = 0,
  ELIGIBLE = 1,
  MAYBE_ELIGIBLE = 2,
  NOT_FOUND = -1,
  NONE = 4
}

export interface CheckRequest {
  email: string;
  licensePlate: string;
  termsOfServiceAgreement: boolean;
  referral: CookieValueTypes;
}

export interface VehicleOwnershipPeriod {
  start: string,
  end: string,
  type: string
}

export interface Company {
  kvkIdentifier: string,
  companyName: string,
  city: string
}

export interface CheckExistingClaimRequest {
  licensePlate: string;
  email: string;
  referenceDateYear: string | number;
  referenceDateMonth: string | number;
  referenceDateDay: string | number;
}

export interface SubmitClaimRequest {
  licensePlate: string;
  email: string;
  termsOfServiceAgreement: boolean;
  isCompany: boolean;
  companyOption?: {
    companyName: string;
    kvkIdentifier: string;
  };
  referenceDateDay: string;
  referenceDateMonth: string;
  referenceDateYear: string;
  firstname: string;
  lastname: string;
  phone: string;
  addressZip: string;
  addressHouseNumber: string;
  addressHouseNumberExtension?: string;
  addressStreetname: string;
  addressCity: string;
  addressCountry: string;

  isLeaseContract: boolean;
  leaseDuration?: number;
  leaseDateStart?: Date;
  leaseCompany?: string;
  leaseCompanyKVK?: string;

  claimantType: string;

  tradeAssociation: string;
  tradeAssociationCustom: string;
  tradeAssociationMembershipNumber: string;

  fbp?: CookieValueTypes;
  fbc?: CookieValueTypes;
}

export interface CheckExistingClaimResponse {
  exists: boolean;
  immutable: boolean;
  created?: Date
}


export interface SubmitCompanyCheckRequest {
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  jobtitle: string;
  companies: { kvkIdentifier: string, companyName: string }[];
  tradeAssociation: string;
  tradeAssociationCustom: string;
  tradeAssociationMembershipNumber: string;

  referral: CookieValueTypes;

  fbp?: CookieValueTypes;
  fbc?: CookieValueTypes;
}
