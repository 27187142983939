import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from "next/link";
import { Brand } from "@/types";
import { brandSupportsIcon, getIconURLForBrand, getPrimaryColorForBrand, useBranding } from "@util/branding.util";
import Head from "next/head";

interface PagePanelProps {
  children?: React.ReactNode;
  blue?: boolean
}

const PagePanel = (props: PagePanelProps) => {
  const branding = useBranding();

  const {
    children, blue,
  }: PagePanelProps = props;

  return (
    <div className={ 'flex flex-col items-center flex-1' }>

      <Head>
        <meta name="theme-color" content={ getPrimaryColorForBrand(branding) }/>
      </Head>

      { blue && <div className={ 'w-full bg-[#0060AB] h-[12px] hidden md:block' }/> }
      { !blue && <div style={ {backgroundColor: getPrimaryColorForBrand(branding)} }
                      className={ 'w-full h-[12px] hidden md:block' }/> }

      <div
        className={ 'relative bg-white shadow-none rounded-none md:rounded-md px-6 py-10 md:p-16 max-w-[500px] w-full flex flex-col gap-4' }>
        <div className={ 'flex flex-row justify-center mb-4' }>
          { brandSupportsIcon(branding) &&
              <Link href={ blue ? 'https://landing.dieselgate.com/bedrijven' : 'https://landing.dieselgate.com' }>
                  <Image
                      alt={'Dieselgate logo'}
                      src={ getIconURLForBrand(branding) }
                      height={ 32 }
                      width={ 32 }
                      objectFit={ 'contain' }
                      priority={ true }
                  />
              </Link> }

          { !brandSupportsIcon(branding) && <Link href={ 'https://www.mercedesrecall.nl/' }>
              <div className={ 'text-[#1D39FF] font-semibold cursor-pointer' }>MercedesRecall.nl</div>
          </Link> }
        </div>
        { children }
      </div>

      <div className={ 'flex-1 flex' }/>

      <div
        className={ 'w-full border-t-[1px] border-t-gray-200 py-4 px-6 text-sm text-gray-600 flex flex-row justify-center' }>
        <div className={ 'max-w-[500px] w-full flex flex-row justify-between' }>
          <div className={ 'flex' }><a href={ 'https://dieselgate.com/privacy/' }
                                       target={ '_blank' }>Privacyverklaring</a></div>
          <div className={ 'flex' }>Powered by Litigo B.V.</div>
        </div>
      </div>

    </div>
  )
}

export default PagePanel;
