import React from "react";
import { ClaimStore } from "./claim.store";
import { CompanyCheckStore } from "src/stores/company-check.store";

const stores = {
  claimStore: new ClaimStore(),
  companyCheckStore: new CompanyCheckStore()
};

const storeContext = React.createContext(stores);
export const useStores = () => React.useContext(storeContext);